




















































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import DialogoDeEdicaoDeTag from '@/components/produto/DialogoDeEdicaoDeTag.vue'
import { FindTagUseCase } from '@/usecases/produto/tag/FindTagUseCase'
import { Tag, Page } from '@/models'
import { CancelToken } from 'axios'
import { Pageable } from '@/models/Pageable'

@Component({
	components: {
		TelaGenerica,
		SeletorDeTag,
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeTag,
	},
})

export default class TelaDeTags extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeTag

	findTagUseCase = new FindTagUseCase()
	tags: Tag[] = []
	busca = ''
	carregando = false
	editando = false
	tag: Tag | null = null

	headers = [
		{ text: 'Tags', value: 'nome' },
	]

	abrirDialogoDeEditarTag(indice: number) {
		this.editando = true
		this.tag = this.tags[indice]
		this.dialogoDeEdicao.mostrar()
	}

	abrirDialogoDeCriarTag() {
		this.editando = false
		this.tag = {id:"",nome:""}
		this.dialogoDeEdicao.mostrar()
	}

	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<Tag>> {
		const pagina = await this.findTagUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.tags = pagina.content
		return pagina
	}

	tagSalva(tagSalva) {
		const indice = this.tags.findIndex(marca => marca.id === tagSalva.id)

		if (indice === -1) {
			this.tags.push(tagSalva)
		} else {
			this.tags.splice(indice, 1, tagSalva)
		}
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
}
